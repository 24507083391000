import { IStyleParam } from '@wix/tpa-settings';

type GetStyleParamType = {
  isMobile: boolean;
  getStyleParamValue: (param: IStyleParam) => any;
  paramName: IStyleParam;
  mobileParamName: IStyleParam;
  dependencyParam: IStyleParam;
};
/**
 * Helps to move away from using separate style params for mobile and desktop.
 *
 * Resolves the default value based on legacy style params created with `createStylesParam()`.
 *
 * @example
 * export default createStylesParams({
 *   showNewestPostsFirstV2: {
 *     type: StyleParamType.Boolean,
 *     getDefaultValue: ({ isMobile, getStyleParamValue }) =>
 *       resolveDefaultStyleParamValue({
 *         isMobile,
 *         getStyleParamValue,
 *         paramName: LEGACY_STYLE_PARAMS.showNewestPostsFirst,
 *         mobileParamName: LEGACY_STYLE_PARAMS.showNewestPostsFirstMobile,
 *         dependencyParam: LEGACY_STYLE_PARAMS.isMobileDisplaySettingsEnabled,
 *       }),
 *   },
 * });
 */
export const resolveDefaultStyleParamValue = ({
  isMobile,
  getStyleParamValue,
  paramName,
  mobileParamName,
  dependencyParam,
}: GetStyleParamType) => {
  if (isMobile) {
    const isMobileSettingsEnabled = getStyleParamValue(dependencyParam);
    if (isMobileSettingsEnabled) {
      const mobileParamValue = getStyleParamValue(mobileParamName);
      if (typeof mobileParamValue !== 'undefined') {
        return mobileParamValue;
      }
    }
  }
  return getStyleParamValue(paramName);
};
